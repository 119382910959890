import { Switch, Route } from "react-router";
import { ContentDashboard } from "./view";
import { ContentUploadInFo } from "./content-upload-info";
import { ContentValidationQuestions } from "./content-validations/list";
import { ContentParikshaTestInfo } from "./pariksha-test/test-info";
import { ContentAbhyaasInfo } from "./abhyaas/abhyaas-info";
import { KaryakartaList } from "./karyakarta/list";
import { KaryakartaInfo } from "./karyakarta/karyakarta-info";


interface Props {
    match?: any;
    courseId: number;
}
const ContentRoutes = (props: Props) => {

    let url: string | undefined = props.match?.url;
    if (url?.endsWith('/')) {
        url = url.substr(0, url.length - 1);
    }

    return (
        <Switch>
            <Route exact path={`${url}/view`} >
                <ContentDashboard></ContentDashboard>
            </Route>
            <Route path={`${url}/question-validation`} exact>
                <ContentValidationQuestions></ContentValidationQuestions>
            </Route>
            <Route path={`${url}/abhyaas/worksheet`} exact>
                <ContentAbhyaasInfo></ContentAbhyaasInfo>
            </Route>
            <Route path={`${url}/karyakarta/list`} exact>
                <KaryakartaList></KaryakartaList>
            </Route>
            <Route path={`${url}/karyakarta/info/:userCode/:userName/:userRole`} exact>
                <KaryakartaInfo></KaryakartaInfo>
            </Route>
            <Route path={`${url}/pariksha/test-creation`} exact>
                <ContentParikshaTestInfo></ContentParikshaTestInfo>
            </Route>
            <Route path={`${url}/upload/boards-list`} exact>
                <ContentUploadInFo></ContentUploadInFo>
            </Route>
            <Route path={`${url}/upload/add-question`} exact>
                <ContentUploadInFo></ContentUploadInFo>
            </Route>
            <Route path={`${url}/upload/add-videos`} exact>
                <ContentUploadInFo></ContentUploadInFo>
            </Route>
            <Route path={`${url}/upload/question-source`} exact>
                <ContentUploadInFo></ContentUploadInFo>
            </Route>
            <Route path={`${url}/upload/question-code`} exact>
                <ContentUploadInFo></ContentUploadInFo>
            </Route>
            <Route path={`${url}/upload/question-status`} exact>
                <ContentUploadInFo></ContentUploadInFo>
            </Route>
            {/* <Route path={`${url}/upload/mediums`} >
                <ContentUploadInFo></ContentUploadInFo>
            </Route> */}
            <Route path={`${url}/upload/board/:BoardUuid/:BoardName/medium`} exact>
                <ContentUploadInFo></ContentUploadInFo>
            </Route>
            <Route path={`${url}/upload/board/:BoardUuid/:BoardName/:MediumUuid/:MediumdName/standard`} exact>
                <ContentUploadInFo></ContentUploadInFo>
            </Route>
            <Route path={`${url}/upload/board/:BoardUuid/:BoardName/:MediumUuid/:MediumdName/:StandardUuid/:StandardName/subject`} exact>
                <ContentUploadInFo></ContentUploadInFo>
            </Route>
            <Route path={`${url}/upload/board/:BoardUuid/:BoardName/:MediumUuid/:MediumdName/:StandardUuid/:StandardName/:SubjectUuid/:SubjectName/chapter`} exact>
                <ContentUploadInFo></ContentUploadInFo>
            </Route>
            <Route path={`${url}/upload/board/:BoardUuid/:BoardName/:MediumUuid/:MediumdName/:StandardUuid/:StandardName/:SubjectUuid/:SubjectName/:ChapterUuid/:ChapterName/topic`} exact>
                <ContentUploadInFo></ContentUploadInFo>
            </Route>

        </Switch>
    );
}
export default ContentRoutes;